//todo: переписать логику компонента, чтобы можно было добавлять несколько точек загрузки
export default {
  data () {
    return {
      isLoading: {
        data: false
      }
    }
  },
  computed: {
    loading () {
      let isLoading = false
      for (let key in this.isLoading) {
        if (this.isLoading.hasOwnProperty(key) && this.isLoading[key]) {
          isLoading = true
          break
        }
      }
      return isLoading
    },
    isHandbookLoading () {
      return this.$store.getters['handbook/isLoading']
    },
    isServiceDataLoading () {
      return this.$store.getters['serviceData/isLoading']
    }
  }
}
