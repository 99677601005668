import _ from 'lodash'
import { flatenObject } from '@/components/utils/common'

export default {
  data () {
    return {
      passports: {},
      objectsSchema: [],
      filter: ''
    }
  },
  computed: {
    /*passportsByType () { // формирование объекта по типу паспорта
      this.isLoading.data = true
      const result = {}
      for (let key in this.passports) {
        const objectByType = this.passports[key].type // тип, например "озеленение территории"
        if (!result[objectByType]) {
          result[objectByType] = { // инициализация объекта этого типа, если его еще нет в result
            totalArea: Number(this.passports[key].area),
            objects: this.reduceCategory(this.passports[key].objects, undefined),
            count: 1 //к-во паспортов этого типа
          }
        } else {
          //иначе мы дополняем
          result[objectByType].totalArea += Number(this.passports[key].area)
          result[objectByType].objects = this.reduceCategory(this.passports[key].objects, result[objectByType].objects)
          result[objectByType].count += 1
        }
      }
      this.isLoading.data = false
      return result
    }*/
  },
  methods: {
    async getObjectsSchema () {
      this.isLoading.data = true
      const responseObjectsSchema = await this.$store.dispatch('server/get', { url: '/object/schema' }, { root: true })
      await this.$set(this, 'objectsSchema', responseObjectsSchema)
      this.isLoading.data = false
      return true
    },
    async getStatistics () {
      this.isLoading.data = true
      const response = await this.$store.dispatch('server/get', { url: '/statistics' + this.filter }, { root: true })
      this.$set(this, 'passports', { ...response })
      this.formatStatisticObjects(response)
      this.isLoading.data = false
      return true
    },
    /*  reduceCategory (objects1, objects2) { //функция суммирующия состояния из всех паспортов
        function customizer (objValue, srcValue, idObject) { // пропсы: 1.новый объект; 2.тот, в который складывается всё; 3.id самого объекта
          if (objValue) {
            const keyObject = _.findKey(objValue, function (o, key) { //поиск цифрового ключа в объекте(категория состояния)
              return typeof +key === 'number'
            })

            let kategoriya_sostoyaniya = _.reduce(objValue?.[keyObject], function (result, value, key) { //складываем здесь состояния
              if (srcValue?.kategoriya_sostoyaniya && !!srcValue?.kategoriya_sostoyaniya[key]) { // если объект уже был сформирован
                result[key] = Number(value) + Number(srcValue.kategoriya_sostoyaniya[key]) // то мы из нового объекта складываем значение с элементом из уже существующего
                delete srcValue.kategoriya_sostoyaniya [key] // затем удаляем его
              } else {
                result[key] =
                  srcValue[keyObject] && Number(srcValue[keyObject][key]) ? // это условие вроде работает для самого первого объекта в списке
                    Number(srcValue[keyObject][key]) + Number(value) : // и складываем с ним
                    +value // иначе оставляем текущее значение
              }

              return result
            }, {})

            kategoriya_sostoyaniya = { ...srcValue?.kategoriya_sostoyaniya || srcValue?.[keyObject], ...kategoriya_sostoyaniya } // складывание категории состояния в целый объект:
            // { 'удовлет'. : 12, 'неудовлет'. : 5, 'отсутст'. статус : 6 }, а изначально она здесь состовляется из одного

            return {
              total_count: objValue.total_count + srcValue.total_count,
              kategoriya_sostoyaniya
            }
          } else {
            return srcValue
          }
        }

        return _.assignInWith(objects1, objects2, customizer)
      },*/
    formatStatisticObjects (passports) {
      for (const keyPassport in passports) {
        const objects = passports[keyPassport].objects

        for (const keyObj in objects) {
          let objToUpdate = objects[keyObj]

          for (const attribute in objToUpdate) {
            if (_.isObject(objToUpdate[attribute]) && !_.isEmpty(objToUpdate[attribute])) {
              if (objToUpdate[attribute].categoryId) {
                let category
                let categoryId = objToUpdate[attribute].categoryId

                if (attribute === 'rekomendatsii' && (_.has(objToUpdate[attribute], 'trees') || _.has(objToUpdate[attribute], 'bushes'))) {
                  for (const bushesOrTrees in objToUpdate[attribute]) {
                    if ((bushesOrTrees === 'bushes' || bushesOrTrees === 'trees') && (_.isObject(objToUpdate[attribute][bushesOrTrees]) && !_.isEmpty(objToUpdate[attribute][bushesOrTrees]))) {
                      category = objToUpdate[attribute][bushesOrTrees]
                      category = this.processCategory(category, categoryId)
                    }
                  }
                } else {
                  category = objToUpdate[attribute]
                  category = this.processCategory(category, categoryId)
                }
              } else {
                // удаляем атрибуты которые должны быть справочными, а они почему-то нет
                delete objToUpdate[attribute]
              }
            }
          }
        }
      }
    },
    processCategory (category, categoryId) {
      _.each(category, (value, key) => {
        if (key !== 'categoryId') {
          const categoryValueId = this.parseCategoryValue(key)

          const categoryValue = this.$store.getters['handbook/getCategoryValue'](categoryId, categoryValueId) || 'Отсутствует статус'

          category[categoryValue] = value
        }
        delete category[key]
      })

      return category
    },
    parseCategoryValue (value) {
      // Проверяем, является ли значение строкой вида "[число]"
      const match = value.match(/^\[(\d+)\]$/)

      if (match) {
        // Если совпадение найдено, возвращаем число
        return parseInt(match[1], 10)
      } else {
        // Иначе просто преобразуем значение в число
        return parseInt(value, 10)
      }
    },
    getCurrentObject (id) {
      return this.objectsSchema.find((item) => +item.id === +id)
    }
  },
  async mounted () {
    await this.getObjectsSchema()
    await this.getStatistics()
  }
}