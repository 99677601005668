<template>
  <v-card
    width="200"
    min-height="190"
    outlined
    @click="$emit('click')"
    class="card-statistic lh-normal"
    v-if="statistic.total_count !== 0"
    :style="'background: linear-gradient(90deg, ' + `${objectsSchema.style.color}` + '99.9%,'  + `${objectsSchema.style.color}` + ' 65.9%);' "
  >
    <v-card-text class="card-statistic-header pa-2 pt-3 d-flex justify-start align-center mb-4">
      <div class="entity-wrapper">
        <div class="entity-count">{{ statistic.total_count }}</div>
        <div class="entity-title">
          <v-icon color="white"
                  small>{{ objectsSchema.icon }}
          </v-icon>
          {{ objectsSchema.entityName }}
        </div>
      </div>
    </v-card-text>
    <BaseStatistics :statistic="statistic"/>
  </v-card>
</template>

<script>
import BaseStatistics from '@/components/base/BaseStatistics'

export default {
  name: 'BaseCardStatistic',
  components: { BaseStatistics },
  props: {
    statistic: Object,
    objectsSchema: Object
  }
}
</script>

<style scoped>
  .v-card--link:before {
    background: inherit;
  }
  .card-statistic {
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    flex-shrink: 0;
    gap: 8px;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
  }
  .card-statistic-header {
    height: 56px;
  }
  .card-statistic * {
    color: #fff;
  }
  .entity-count {
    font-size: 2rem;
    font-weight: bold;
  }
  .entity-title {
    font-size: 0.8rem;
    text-align: right;
  }
  .entity-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    z-index: 1;
  }
</style>