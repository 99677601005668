<template>
  <BaseDetail
    :is-scroll="$route.name !== 'map'"
    :is-loading="loading"
    style="background: white !important"
    class="base-detail"
  >
    <template #header>
      <div></div>
    </template>
    <template #content>
      <v-card
        v-if="Object.keys(passports).length !== 0"
        outlined
        flat
        style="min-height: 100vh"
        :class="$route.name === 'dashboard' ? 'pl-5' : ''"
        class="ma-4 rounded-xl"
        color="#FAFAFA"
      >
        <v-card-title
          v-if="$route.name === 'dashboard'"
          class="text-h3 font-weight-bold pt-2 pb-2 pl-0"
        > Дашборд
        </v-card-title>
        <div
          v-for="(value, key, index) in passports"
          :key="key"
        >
          <PassportInfo
            :statistics="value"
            :type="key"
            class="mt-1"
            :objects-schema="objectsSchema"
          />
          <v-divider
            v-if="Object.keys(passports).length - 1 !== index"
            class="mt-9 mb-8"
          />
        </div>
      </v-card>
      <BaseAlert
        v-else
        type="error"
        text="Нет активных подписок"
      />
    </template>
  </BaseDetail>
</template>

<script>
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import PassportInfo from '@/components/views/account/dashboard/passports/PassportInfo'
import loading from '@/components/mixins/loading'
import BasePreloader from '@/components/base/UI/BasePreloader'
import BaseAlert from '@/components/base/UI/BaseAlert'
import BaseDetail from '@/components/base/BaseDetail'
import dashboard from '@/components/mixins/dashboard'

export default {
  name: 'Dashboard',
  mixins: [loading, dashboard],
  components: {
    BaseAlert,
    BasePreloader,
    PassportInfo,
    BaseHeader,
    BaseDetail
  }
}
</script>

<style scoped>
  * {
    line-height: normal !important;
  }
  .base-detail > /deep/ .content {
    background: white !important;
  }
</style>
