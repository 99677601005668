<template>
  <div>
    <div
      v-if="$route.name !== 'map'"
      style="grid-gap: 15px"
      class="d-flex flex-row align-center mb-2 lh"
    >
      <v-card-title
        v-if="currentTypesPassport"
        class="text-h5 width200 font-weight-bold ml-0 pl-0 lh"
        style="max-width: 200px;overflow-wrap: normal;word-break: break-word;"
      >{{ currentTypesPassport.label }}
      </v-card-title>
      <div class="subtitle-1 mr-4 ma-0 text--darken-2 grey--text lh">
        <b>{{ $t('dashboard.countPassports') }}</b>
        <p class="ma-0">{{ statistics.count }} шт</p>
      </div>
      <div class="subtitle-1 ma-0 mr-4 text--darken-2 grey--text lh">
        <b>{{ $t('dashboard.squarePassport') }}</b>
        <p class="ma-0">{{ Math.floor(statistics.area) }} м<sup>2</sup></p>
      </div>
      <div class="subtitle-1 ma-0 text--darken-2 grey--text lh">
        <b>{{ $t('dashboard.actual') }}</b>
        <p class="ma-0">{{ currentDate }}</p>
      </div>
    </div>

    <div
      style="grid-gap: 15px"
      class="d-flex flex-wrap"
      :class="$route.name === 'dashboard' ? 'ml-2' : ''"
    >
      <BaseCardStatistic
        v-if="item && item.total_count > 0"
        v-for="(item, key) in statistics.objects"
        :key="key"
        :statistic="item"
        :objects-schema="getCurrentObject(key)"
      />
    </div>
  </div>
</template>

<script>
import BaseStatistics from '@/components/base/BaseStatistics'
import BaseCardStatistic from '@/components/views/account/dashboard/passports/BaseCardStatistic'
import formatters from '@/components/mixins/formatters'

export default {
  name: 'PassportInfo',
  mixins: [formatters],
  components: { BaseCardStatistic, BaseStatistics },
  props: {
    statistics: Object,
    type: String,
    objectsSchema: Array
  },
  data () {
    return {}
  },
  computed: {
    currentTypesPassport () {
      return this.$store.getters['serviceData/get']('passport.type')?.find((item) => item.value === +this.type) // "озеленение территории
    }
  },
  methods: {
    getCurrentObject (id) {
      return this.objectsSchema.find((item) => +item.id === +id)
    }
  }
}
</script>

<style scoped>
  .lh {
    line-height: 1.2rem !important
  }
</style>