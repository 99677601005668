<template>
  <div
    v-if="isStatisticsReceived && isShowStatistics"
    class="pl-2 mt-2"
  >
    <div v-if="Object.keys(statistic.kategoriya_sostoyaniya).length">
      <div class="mb-1"><b>{{ $t('dashboard.state') }}</b></div>
      <div class="statistic-item-wrapper">
        <div v-for="(item, key) in statistic.kategoriya_sostoyaniya"
             :key="key"
             class="statistic-item">
          {{ key + ' - ' + item }}
        </div>
      </div>
    </div>
    <div v-if="Object.keys(formattedData).length && hasPositiveValue(formattedData)">
      <div class="mt-1 mb-1"><b>Рекомендации</b></div>
      <div class="statistic-item-wrapper mb-1">
        <div v-for="(item, key) in formattedData"
             v-if="item"
             :key="key"
             class="statistic-item">
          {{ key + ' - ' + item }}
        </div>
      </div>
    </div>
    <div v-if="statistic.ploshchad_kvm">
      <div class="statistic-item-wrapper mt-1 mb-1">
        <div class="statistic-item">
          <b>Общая площадь - {{ ploshchad }}</b>
        </div>
      </div>
    </div>
  </div>
  <div v-else
       class="nodata-wrapper">
    <div class="nodata">{{ $t('dashboard.noData') }}</div>
  </div>
</template>

<script>
import user from '@/components/mixins/user'

export default {
  name: 'BaseStatistics',
  mixins: [user],
  props: {
    statistic: Object
  },
  computed: {
    isShowStatistics () {
      return this.$route.name === 'dashboard' ? ['auditor', 'unit', 'manager'].includes(this.currentRole) : true
    },
    isStatisticsReceived () {
      return (_.isObject(this.statistic.kategoriya_sostoyaniya) && _.size(this.statistic.kategoriya_sostoyaniya))
        || (_.isObject(this.formattedData) && _.size(this.formattedData))
        || this.statistic.ploshchad_kvm
    },
    ploshchad () {
      return parseFloat(this.statistic.ploshchad_kvm).toFixed(2)
    },
    formattedData () {
      if (_.isObject(this.statistic.rekomendatsii)) {
        let treesTrimming = 0
        let treesDestroy = 0
        let bushesTrimming = 0
        let bushesDestroy = 0

        if (_.isObject(this.statistic.rekomendatsii.trees) && _.size(this.statistic.rekomendatsii.trees)) {
          treesTrimming = this.calculateTrimming(this.statistic.rekomendatsii.trees)
          treesDestroy = this.calculateDestroy(this.statistic.rekomendatsii.trees)
        }
        if (_.isObject(this.statistic.rekomendatsii.bushes) && _.size(this.statistic.rekomendatsii.bushes)) {
          bushesTrimming = this.calculateTrimming(this.statistic.rekomendatsii.bushes)
          bushesDestroy = this.calculateDestroy(this.statistic.rekomendatsii.bushes)
        }

        return {
          'Деревьев обрезать': treesTrimming,
          'Кустарников обрезать': bushesTrimming,
          'Деревьев под снос': treesDestroy,
          'Кустарников под снос': bushesDestroy
        }
      } else {
        return {}
      }
    }
  },
  methods: {
    calculateTrimming (obj) {
      return _.sumBy(Object.entries(obj), ([key, value]) => {
        return /обрезка|обрезать/i.test(key) ? parseInt(value, 10) : 0
      })
    },
    calculateDestroy (obj) {
      return _.sumBy(Object.entries(obj), ([key, value]) => {
        return /снос/i.test(key) ? parseInt(value, 10) : 0
      })
    },
    hasPositiveValue(obj) {
      return Object.values(obj).some(value => value > 0);
    }
  }
}
</script>

<style scoped>
  .statistic-item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  .statistic-item {
    color: #fff;
    font-size: 0.8rem;
    white-space: pre-line;
  }
  .nodata-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .nodata {
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
  }
</style>