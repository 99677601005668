import { render, staticRenderFns } from "./PassportInfo.vue?vue&type=template&id=5a181aec&scoped=true&"
import script from "./PassportInfo.vue?vue&type=script&lang=js&"
export * from "./PassportInfo.vue?vue&type=script&lang=js&"
import style0 from "./PassportInfo.vue?vue&type=style&index=0&id=5a181aec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a181aec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardTitle})
